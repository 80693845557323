


























import { computed } from '@vue/composition-api';
import { TimelineItem } from './types';

export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default(): TimelineItem[] {
        return [
          {
            parent: true,
            text: 'Parent'
          },
          {
            parent: false,
            text: 'child'
          }
        ];
      },
      required: true
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  setup(props, { emit }) {
    const steps = computed(() => {
      return props.items;
    });

    function handleSelect(value) {
      emit('SelectTimeline', value);
    }
    return { handleSelect, steps };
  }
};
